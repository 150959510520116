// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Community Feedback',
  slug: '/community-feedback',
  abstract: 'Community Feedback',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Community Feedback', slug: '/learn' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    launch: file(relativePath: { eq: "launch.jpg" }) {
      ...max900
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper pageSchema={pageSchema} {...props}>
    <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
    <h1>Community Feedback</h1>
    <Row
      gutter={[
        { xs: 16, sm: 16, md: 16, lg: 16, xl: 16, xxl: 16 },
        { xs: 48, sm: 48, md: 48, lg: 48, xl: 48, xxl: 48 },
      ]}
    >
      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <div className="feedback n1">
          <p>
            We wanted an ideal society without money, a cashless society. I
            thought we could do it right away. Here we are about 45 years later
            and we doing it now with this Aura. I couldn’t believe there was
            vegan banana bread offered for auras. The very next day I had my
            cake and ate it too! Does it get better than that?
          </p>
          <p>
            <i>
              <strong>– B William, International Zone</strong>
            </i>
          </p>
        </div>
        <div className="feedback n2">
          <p>
            Aura has given me an excellent space to just share food with the
            community. I’m a Newcomer...just been in the Aura Network for a
            month and a half and it let me know many people, many more
            connections. It was amazing.
          </p>
          <p>
            <i>
              <strong>– Archana, Evergreen</strong>
            </i>
          </p>
        </div>
        <div className="feedback n3">
          <p>
            I always wanted to go to a university but somehow it didn’t work
            out. Aura gave me access to people that are happy to share their
            knowledge in my field of interest! So whether I go to a university
            or not, learning is happening.
          </p>
          <p>
            <i>
              <strong>– Tina, Youth Center</strong>
            </i>
          </p>
        </div>
        <div className="feedback n4">
          <p>
            Interacting on the Aura app has give the closest experience with
            “true economy” I’ve ever had at this scale. By this, I mean that
            somehow, in the removing of conventional currency as a method of
            transaction, a sense of generosity flows more, in general, and true
            & living value is spontaneously established. The residual
            consumeristic sense of cost as too much or too “cheap” is much less
            present which instead allows attention to be directed inwardly at
            one’s actual wishes/needs (and the wishes needs of others!). It’s a
            little sneak peek into a shared experience of abundance; in which
            all are made more by giving and receiving. On the Aura app I’ve both
            offered items and received items & services. All led to notably
            lovely human interactions. I’ve met people I wouldn’t have otherwise
            met. All this is merely the first introductory phase of the app.
            Until the time when conventioanl currency is removed altogether, I
            feel Aura app platform is giving a practical transitional method
            which can only evolve further as a value creating medium of
            exchange.
          </p>
          <p>
            <i>
              <strong>– Kaliananda, Surrender</strong>
            </i>
          </p>
        </div>
        <div className="feedback n5">
          <p>
            This Aura platform is just a daring and wonderful initiative. In the
            context of such a project like Auroville it is so precious to
            multiply interactions between the residents. Aura is doing that
            playfully and that is another important aspect of it. One more
            exquisite feature is the interface of the app: I have rarely seen
            such a well thought interface, both at the point of view simple
            graphic beauty and ergonomy.
          </p>
          <p>
            <i>
              <strong>– Rakhal, Sukhavati</strong>
            </i>
          </p>
        </div>
        <div className="feedback n6">
          <p>
            It provides me a platform where I can browse through to find what
            truly suits and offer my skills in the best possible way. It gives
            me a chance to share, communicate and engage with my community
            members. I get to enjoy access to things that are important to me
            like classes, nutrition. I also learn what I can offer that the
            community can benefit from. The system of providing, sharing and
            supporting each other through needs, offerings and donations of
            auras aids to my experience of community life and inclusion.
          </p>
          <p>
            <i>
              <strong>– Maithili, Fertile</strong>
            </i>
          </p>
        </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <div className="feedback n7">
          <p>
            Aura is nurtured and created with an intention. (It) can spread out
            into other domains of everyday life. Even in the little activities
            that we do, in the little offerings we make for the play... Ideal
            the concept of Aura being a medium of exchange, creating a space of
            generosity, abundance. It is what I have been living and feeling.
          </p>
          <p>
            <i>
              <strong>– Anandi, Savitri Bhavan</strong>
            </i>
          </p>
        </div>
        <div className="feedback n8">
          <p>
            I came to Auroville because I wanted to live in a place that looked
            at economics and community differently. I see Aura as portraying
            exactly this, it is happening, its working, and more people are
            becoming a part of it. This is exciting.
          </p>
          <p>
            <i>
              <strong>– Luke, Maison des Jeunes</strong>
            </i>
          </p>
        </div>
        <div className="feedback n9">
          <p>
            The Aura application creates a new kind of community. It helps me to
            look at my life and see what I can offer. It encourages me to be
            brave and reach out to others. I am looking around my home and
            identifying objects that I no longer use. I look inside myself: what
            do I know that I can share. I can offer these things, and knowledge,
            that someone else is interested in or needs. I’m meeting new people
            that otherwise I may not have met. I am more open to what I need
            knowing there is a platform to communicate it.
          </p>
          <p>
            <i>
              <strong>– Amy, International Zone</strong>
            </i>
          </p>
        </div>
        <div className="feedback n10">
          <p>
            Aura is a wonderful platform for exchange and try new activities.
            It's also perfect space to connect with new people from our
            community. Give- it's the best way to receive. Thank you very much
            to the team.
          </p>
          <p>
            <i>
              <strong>– Veronique, Realization</strong>
            </i>
          </p>
        </div>
        <div className="feedback n11">
          <p>
            My experience with Aura is great and very inspiring. It really
            enhances my feeling and sense of community. I enjoy it being based
            on a currency which seems more healthy/fair than money in my
            opinion. The Aura is far beyond the concept of money. It’s proudly
            our means of exchanging with a value which we are defining as a
            community. Also, using this platform enhances my feeling of
            community as I’m sharing what I know well with the community and I
            get to know more people thanks to the app.
          </p>
          <p>
            <i>
              <strong>– Laure, Auromode</strong>
            </i>
          </p>
        </div>
        <div className="feedback n12">
          <p>
            I've been using the Aura app since over three months now. The app is
            constantly improving and implementing the feedback given. In the app
            itself, I have posted simple offers and needs, and they are
            fulfilled almost immediately. I love how it has brought the
            community closer. I have made new friends in Auroville, and helped
            the community too. Aura app is a perfect and simple example of
            alternate economy, and it fits hand-in-glove with Auroville.
          </p>
          <p>
            <i>
              <strong>– Yatharth & Nivetha</strong>
            </i>
          </p>
        </div>
        <div className="feedback n13">
          <p>
            Aura App is amazing app to bring community exchange of various
            products and services. I enjoy sharing food on this app and have
            also received offerings from others. This app has helped me get to
            know people closely through sharing. I really like the way the
            alternate money can be used to not only exchange products and
            services but to build relationships with the community.
          </p>
          <p>
            <i>
              <strong>– Archana, Grace</strong>
            </i>
          </p>
        </div>
      </Col>
    </Row>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
